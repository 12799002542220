import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, ifNotProp, prop } from 'styled-tools';
import externalLinks from 'utils/externalLinks';

const HeroWrapper = styled.div`
  ${ifProp({ $page: 'homepage'}, css`
    min-height: 28.125rem;
  `)}
`;

export const HeroCopy = styled.div`
  align-self: center;
  bottom: ${ifProp({ $page: 'homepage' }, '0', 'auto')};
  margin: 0 auto;
  position: absolute;
  top: ${ifProp({ $page: 'homepage' }, '0', 'auto')};
  width: ${ifProp({ $page: 'homepage' }, '100%', '60%')};
  z-index: 2;

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    ${ifNotProp({ $page: 'homepage' }, css`
      align-self: flex-start;
      margin-left: 2rem;
    `)}
  }
`;

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  heroImage: PropTypes.string,
  heroType: PropTypes.string,
  mobileHero: PropTypes.string,
  tabletHero: PropTypes.string,
  page: PropTypes.string,
  showPromoBar: PropTypes.bool,
}

const HeroComponent = ({
  children,
  className,
  page,
  showPromoBar,
  heroImage,
  mobileHero,
  tabletHero,
  heroType,
  showAlert
}) => {
  return (
    <HeroWrapper $page={page} showPromoBar={showPromoBar} className={className}>
      {mobileHero && (
        <MobileImage alt='hero image' heroType={heroType} $page={page} src={mobileHero} />
      )}
      {tabletHero && (
        <TabletImage alt='hero image' heroType={heroType} $page={page} src={tabletHero} />
      )}
      {heroImage && (
        <MainImage alt='hero image' heroType={heroType} $page={page} src={heroImage} />
      )}
      <HeroCopy $page={page}>{children}</HeroCopy>
    </HeroWrapper>
  )
};

export const MainImage = styled.img`
  display: block;
  min-width: 100%;
  object-fit: cover;
  min-height: 30.25rem;

  && {
    display: inline-block;
  }

  ${ifNotProp({ heroType: 'mobile' }, css`
    width: 100%;

    @media (max-width: ${prop('theme.breakpoints.md')}) {
      height: inherit;
      object-position: 70%;
    }
  `)}

  ${ifProp({ heroType: 'mobile' }, css`
    && {
      display: none;

      @media (max-width: 900px) {
        object-position: 40% 100%;
      }

      @media (min-width: ${prop('theme.breakpoints.xl')}) {
        display: inline-block;
      }
    }
  `)}

  ${ifProp({ $page: 'homepage'}, css`
    min-height: 28.125rem;
  `)}
`;

export const MobileImage = styled.img`
  display: none;

  ${ifProp({ heroType: 'mobile' }, css`
    && {
      display: inline-block;
      margin-bottom: -2rem;
      min-width: 100%;
      object-fit: cover;

      @media (min-width: ${prop('theme.breakpoints.sm')}) {
        display: none;
      }
    }
  `)}
`;

export const TabletImage = styled.img`
  display: none;

  ${ifProp({ heroType: 'mobile' }, css`
    && {
      display: none;
      margin-bottom: -2rem;
      min-width: 100%;
      object-fit: cover;

      @media (min-width: ${prop('theme.breakpoints.sm')}) {
        display: inline-block;
        min-height: 30rem;
      }

      @media (min-width: ${prop('theme.breakpoints.xl')}) {
        display: none;
      }
    }
  `)}
`;

const Hero = styled(HeroComponent)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: ${prop('theme.colors.white')};
  margin-top: 4rem;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  ${ifProp(
    '$showAlert',
    css`
      margin-top: 7rem;
    `
  )}

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    margin-top: 8.0625rem;

    ${ifProp(
      '$showAlert',
      css`
        margin-top: 11.0625rem;
      `
    )}
  }

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    height: ${ifProp({ heroType: 'mobile' }, 'auto', '17.25rem')};
  }

  ${ifProp(
    'heroVideo',
    css`
      width: 100%;
      margin: 0 auto;
    `
  )}

  ${ifProp('hideGradient', '', css`
    &:before {
      background:
        linear-gradient(to right,	${prop('theme.colors.secondaryBlack')},	transparent);
      bottom: 0;
      top: 0;
      right: 0;
      left: 0;
      content: ' ';
      position: absolute;
      width: 90%;
    }
  `)}

  p {
    font-weight: 700;

    @media (min-width: ${prop('theme.breakpoints.sm')}) {
      font-size: 1.8em;
    }
  }

  h1 {
    font-size: 2.1875rem;
    font-weight: 700;
    margin-bottom: 0;
    text-transform: uppercase;

    @media (min-width: ${prop('theme.breakpoints.sm')}) {
      font-size: 2.75rem;
    }

    @media (min-width: ${prop('theme.breakpoints.md')}) {
      font-size: 4em;
    }
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 0;

    @media (min-width: ${prop('theme.breakpoints.sm')}) {
      font-size: 1.5625rem;
    }

    @media (min-width: ${prop('theme.breakpoints.md')}) {
      font-size: 2.5em;
    }
  }
`;

Hero.propTypes = propTypes;

export default Hero;
