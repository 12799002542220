import styled from 'styled-components';

const Headline = styled.h1`
  color: ${props => props.theme.colors.black};
  text-transform: uppercase;
  text-align: center;
  font-size: 3em;
  font-weight: bold;
  margin-top: 3.75rem;
  margin-bottom: 3.5rem;

  p {
    text-transform: none;
    font-size: 0.7em;
    margin-top: 0;
  }

  span {
    color: ${props => props.theme.colors.primaryRed};
  }
`;

export default Headline;
