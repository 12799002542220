import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';

const SmallPrint = styled.p`
  color: ${prop('theme.colors.darkGray')};
  font-family: 'Open Sans';
  font-size: 0.75rem;
  font-style: italic;
  
  &.center-text {
    text-align: center;
  }
  
  &.medium-font {
    font-weight: 600;
  }
  
  &.red-text {
    color: ${props => props.theme.colors.primaryRed};
  }

  @media (min-width: ${prop('theme.breakpoints.xs')}) {
    font-size: 0.875rem;
  }

  ${ifProp('bodyText', css`
    color: ${prop('theme.colors.black')};
    font-style: normal;
    margin-bottom: 1rem;

    @media (min-width: ${prop('theme.breakpoints.md')}) {
      margin-bottom: 1.75rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  `)}

  ${ifProp('bold', css`
    font-weight: 700;
  `)}

  ${ifProp('dark', css`
    color: ${prop('theme.colors.black')};
  `)}

  ${ifProp('light', css`
    color: ${prop('theme.colors.lightGray')};
  `)}

  ${ifProp('important', css`
    color: ${prop('theme.colors.primaryRed')};
  `)}
  
  ${ifProp('gutterTop', css`
    margin-top: 2rem;
  `)}
`;

export default SmallPrint;
