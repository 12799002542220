import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import grungeDark from 'images/grunge-background-dark2.png';
import SmallPrint from './SmallPrint';
import TastyButton from 'components/TastyButton';

const Section = styled.section`
  ${ifProp('withPadding', css`
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      padding-left: 2.25rem;
      padding-right: 2.25rem;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      padding-left: 10.125rem;
      padding-right: 10.125rem;
    }
  `)}

  ${ifProp('dark', css`
    background: ${prop('theme.colors.primaryBlack')} url(${grungeDark}) no-repeat center / cover;
    color: ${prop('theme.colors.white')};
  `)}

  h1 {
    font-size: 1.625rem;
    font-weight: bold;
    text-transform: uppercase;
    color: ${props => props.theme.colors.black};

    @media (min-width: ${props => props.theme.breakpoints.xs}) {
      font-size: 2rem;
    }

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 2.5rem;
    }
  }

  p:not(${SmallPrint}) {
    font-family: 'Open Sans';
    font-size: 0.875rem;
    font-weight: 400;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 1.125rem;
    }
  }

  a:not(${TastyButton}) {
    color: ${props => props.theme.colors.black};
    font-weight: 500;
    text-decoration: underline;

    &:hover {
      color: ${props => props.theme.colors.black};
    }
  }

  .padded-row {
    padding: 0 1.25rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      padding: 0 2.25rem;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      padding: 0 2.25rem;
    }
  }

  & > * {
    flex: 1 1 50%;
  }
`;

export default Section;
