import styled, { css } from 'styled-components';
import { Container } from 'reactstrap';
import Headline from './Headline';
import { ifProp } from 'styled-tools';

const SectionsWrapper = styled(Container)`
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.65),
  0 9px 36px 8px rgba(0, 0, 0, 0.65), 0 11px 15px -7px rgba(0, 0, 0, 0.65);
  margin: 0 auto;
  position: relative;

  ${Headline} {
    margin: 0;
    padding: 3.75rem 0 3.5rem 0;
  }

  ${ifProp('noGutters', css`
    &.container {
      padding-left: 0;
      padding-right: 0;
    }
  `)}
`;

export default SectionsWrapper;
