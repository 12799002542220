import React from 'react'
import { Link } from 'gatsby';
import styled, { css } from 'styled-components'
import { prop, ifProp } from 'styled-tools'
import { Navbar, NavItem, NavLink, Nav, Container } from 'reactstrap'
import SectionsWrapper from './SectionsWrapper'
import Section from './Section'

const AccountNavbar = styled(Navbar)`
  background: ${prop('theme.colors.lighterGray')};
  z-index: 100;

  &&& {
    padding: 0;
    position: sticky;
    top: 4rem;

    @media (min-width: ${prop('theme.breakpoints.lg')}) {
      top: 5.5625rem;
    }

    @media (max-width: ${prop('theme.breakpoints.md')}) {
      display: none;
    }
  }
`;

const AccountNavWrapper = styled(SectionsWrapper)`
  background: ${prop('theme.colors.lighterGray')};
  box-shadow: none;
`;

const AccountNav = styled(Nav)`
  align-items: center;
`;

const AccountItem = styled(NavItem)`
  font-family: ${prop('theme.fonts.default')};
  font-size: 0.875rem;

  @media (min-width: ${prop('theme.breakpoints.xl')}) {
    font-size: 1rem;
  }

  ${ifProp('bold', css`
    font-weight: 700;
  `)}

  &:first-child {
    padding: 0.75rem 0;
  }
`;

const AccountLink = styled(NavLink)`
  border-bottom: 0.125rem solid transparent;
  text-transform: capitalize;

  &&& {
    padding: 0.75rem;
    text-decoration: none;

    @media (min-width: ${prop('theme.breakpoints.xl')}) {
      padding: 0.75rem 1rem;
    }
  }

  &:hover,
  &:active {
    border-bottom: 0.125rem solid ${prop('theme.colors.primaryRed')};
    color: ${prop('theme.colors.black')};
  }
`;

function AccountTypesNav ({ showPromoBar }) {
  let accountList = ['individual', 'joint'];

  return (
    <AccountNavbar color='#000000' showPromoBar={showPromoBar}>
      <AccountNavWrapper noGutters>
        <Section withPadding>
          <AccountNav>
            <AccountItem bold='bold'>
              Account Types:
            </AccountItem>
            {accountList.map((accountItem) => {
              return (
                <AccountItem key={`${accountItem}-account`}>
                  <AccountLink href={`#${accountItem}`}>
                    {accountItem}
                  </AccountLink>
                </AccountItem>
              )
            })}
          </AccountNav>
        </Section>
      </AccountNavWrapper>
    </AccountNavbar>
  )
}

export default AccountTypesNav;
