import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import Section from './Section';

const HomepageSection = styled(Section)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-left: 0;
  padding-right: 0;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    padding-left: 10.125rem;
    padding-right: 10.125rem;
  }

  ${ifProp('finalSection', css`
    padding-bottom: 3rem;

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      padding-bottom: 6rem;
    }
  `)}

  img {
    max-width: 100%;
  }
`;

export default HomepageSection;
