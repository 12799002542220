import React from 'react';
import PropTypes from 'prop-types';
import TastyButton from 'components/TastyButton';
import styled from 'styled-components';
import redGrunge from 'images/red-grunge.jpg';
import HomepageSection from 'components/HomepageSection';
import externalLinks from 'utils/externalLinks';
import { prop } from 'styled-tools';

const propTypes = {};

const defaultProps = {};

export const ReadyToTradeSection = styled(HomepageSection)`
  background: url(${redGrunge}) no-repeat left top;
  background-size: cover;
  color: ${props => props.theme.colors.white};
  display: block;
  padding: 3rem 4rem;
  text-align: center;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 3rem 5rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    padding: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: ${props => props.theme.breakpoints.xs}) {
    font-size: 1.5rem;
  }

  ${TastyButton} {
    background: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.primaryBlack};

    &:hover {
      background: ${props => props.theme.colors.primaryBlack};
      color: ${props => props.theme.colors.white};
    }
  }

  & > * {
    flex: 0 1 auto;

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      margin: 0 1.5rem;
    }
  }
`;

const H2 = styled.h2`
  font-size: 2.25rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.5;
  color: ${props => props.theme.colors.white};

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    font-size: 1.7rem;
  }
`;

const OpenAccountButton = styled(TastyButton)`
  padding: 0.9em 3em;

  &&& {
    color: ${prop('theme.colors.black')};

    &:hover {
      color: ${prop('theme.colors.white')};
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    padding: 1rem;
  }
`;

function ReadyToTrade() {
  return (
    <ReadyToTradeSection>
      <H2>Ready to Trade?</H2>
      <OpenAccountButton href={externalLinks.openAnAccount}>Open an Account</OpenAccountButton>
    </ReadyToTradeSection>
  );
}

ReadyToTrade.propTypes = propTypes;
ReadyToTrade.defaultProps = defaultProps;

export default ReadyToTrade;
