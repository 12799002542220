import styled from 'styled-components';
import sectionDivider from '../images/ring-divider.svg';

const SectionDivider = styled.div`
  && {
    background: url(${sectionDivider}) repeat-x left top;
    background-size: 8px 8px;
    padding-top: 2rem;
    margin-top: 2em;
    flex-basis: 100%;
  }
`;

export default SectionDivider;
